<template>
  <div class="registart_box">
    <div class="rb_backimg">
      <div class="rb_content">
        <p class="rb_logo">
          <img src="../assets/img/PMlogo.png" alt />
        </p>
        <div class="rb_fillinfor">
          <div class="rb_fillp">
            <el-form :model="data" ref="data">
              <div class="rb_title">
                <div class="rb_top">
                  <el-upload
                    class="avatar-uploader"
                    action="image"
                    :show-file-list="false"
                    :http-request="uploadImages"
                  >
                    <img v-if="data.avatar" :src="data.avatar" class="avatar" />
                    <p v-else class="avatar">Upload Avatar</p>
                  </el-upload>
                </div>
              </div>
              <div class="rb_row">
                <div class="rb_width">
                  <el-form-item
                    prop="first_name"
                    :rules="[
                    { required: true, message: 'this information is required', trigger: ['blur' ,'change']}]"
                  >
                    <p class="tip">First Name</p>
                    <el-input v-model="data.first_name" placeholder></el-input>
                  </el-form-item>
                </div>
                <div class="rb_width">
                  <el-form-item
                    prop="last_name"
                    :rules="[
                    { required: true, message: 'this information is required', trigger: ['blur','change'] }]"
                  >
                    <p class="tip">Last Name</p>
                    <el-input v-model="data.last_name" placeholder></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="rb_row">
                <div class="rb_width">
                  <el-form-item
                    prop="email"
                    :rules="[{ required: true, message: 'this information is required', trigger: 'blur' },{ type: 'email', message: 'Please input correct email address', trigger: ['blur'] }]"
                  >
                    <p class="tip">Email</p>
                    <el-input v-model="data.email" placeholder :disabled="true"></el-input>
                  </el-form-item>
                </div>
                <div class="rb_width">
                  <el-form-item>
                    <p class="tip">Phone</p>
                    <el-input
                      v-model="data.phone"
                      maxlength="22"
                      @input="e=>data.phone=changePhone(e)"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <!-- <div class="rb_row">
                <div class="rb_width">
                  <el-form-item>
                    <p class="tip">Education</p>
                    <el-input v-model="data.education" placeholder></el-input>
                  </el-form-item>
                </div>
                <div class="rb_width">
                  <el-form-item>
                    <p class="tip">Community organizations</p>
                    <el-input v-model="data.community_organization" placeholder></el-input>
                  </el-form-item>
                </div>
              </div> -->
              <!-- <div>
                <p class="tip">SOCIAL MEDIA</p>
                <div class="profile_social_row">
                  <span class="profile_icon">
                    <img src="../../assets/img/smaill_link.png" alt />
                  </span>
                  <el-form-item>
                    <el-input v-model="data.social.link" />
                  </el-form-item>
                </div>
                <div class="profile_social_row">
                  <span class="profile_icon">
                    <img src="../../assets/img/smaill_instag.png" alt />
                  </span>
                  <el-form-item>
                    <el-input v-model="data.social.instagram" />
                  </el-form-item>
                </div>

                <div class="profile_social_row">
                  <span class="profile_icon">
                    <img src="../../assets/img/smaill_face.png" alt />
                  </span>
                  <el-form-item>
                    <el-input v-model="data.social.facebook" />
                  </el-form-item>
                </div>
              </div> -->
            </el-form>
            <el-button
              type="primary"
              @click="submitPeosonInfor"
              class="verifi"
              v-loading.fullscreen.lock="fullscreenLoading"
            >SIGN UP NOW</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { apiUploadImage, apiUpdateProfile } from "../API/api";
import { Minixs } from "../js/mixins";
export default {
  mixins: [Minixs],
  data() {
    return {
      fullscreenLoading: false,
      data: {
        avatar: "",
        // social: {},
        first_name: "",
        last_name: "",
        phone: "",
        email:"",
      }
    };
  },
  methods: {
    submitPeosonInfor() {
      this.$refs.data.validate(valid => {
        if (valid) {
          apiUpdateProfile(this.data)
            .then(res => {
              this.fullscreenLoading = false;
              this.$router.push("/dashboard");
            })
            .catch(err => {
              this.fullscreenLoading = false;
              this.$message("Failed to submit, please resubmit");
            });
        } else {
          return false;
        }
      });
    },

    uploadImages(file) {
      let form = new FormData();
      form.append("file", file.file);
      apiUploadImage(form)
        .then(res => {
          this.data.avatar = res.url;
        })
        .catch(err => {
          this.$message("Image upload failed");
        });
    }
  },
  created() {
    this.data.email = this.$route.params.email;
  }
};
</script>
<style lang="scss" scoped>
/* social的样式 */
.registart_box {
  .rb_backimg {
    height: 100vh;
    width: 100%;
    background-image: url("../assets/img/20191218172529.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: PingFangSC-Regular, sans-serif;
    .rb_content {
      width: 550px;
      .rb_logo {
        text-align: left;
        margin-bottom: 25px;
        color: #fff;
      }
      .rb_fillinfor {
        background: white;
        padding: 20px 40px;
        border-radius: 4px;
        .profile_social_row {
          display: flex;
          align-items: center;
          margin-top: 10px;
          .profile_icon {
            margin-right: 10px;
            img {
              height: 40px;
              width: 40px;
            }
          }
        }
        /deep/ .el-form-item {
          margin-bottom: 0;
          width: 100%;
        }

        .rb_top {
          display: flex;
          align-items: center;
          justify-content: center;
          .avatar {
            width: 100px;
            height: 100px;
            background: #f7f7f7 0% 0% no-repeat padding-box;
            border: 1px solid #e6e6e6;
            border-radius: 50%;
            text-align: center;
            line-height: 100px;
            font-size: 14px;
            font-family: PingFangSC-Medium, sans-serif;
            color: #666;
          }
        }
        .tip {
          text-align-last: left;
          margin: 0px 0 0 0;
          font-size: 16px;
        }
        .rb_row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .rb_width {
            width: calc(calc(100% - 20px) / 2);
          }
        }
      }
    }
  }
  .el-button {
    margin-top: 40px;
    width: 400px;
    height: 50px;
    background: #678993;
    border-color: #678993;
    font-size: 18px;
    color: #fff;
  }
}
</style>
